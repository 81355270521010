import Navbar from '../../module/common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../module/common/Loading.vue'
import Share from '../../module/common/Share.vue'
import { Tabs, Tab, Image, Icon, Tag } from 'vant'
export default {
    components: {
        Navbar: Navbar,
        Tabbar: Tabbar,
        Loading: Loading,
        Share: Share,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Image.name]: Image,
        [Icon.name]: Icon,
        [Tag.name]: Tag
    },
    data () {
        return {
            activeTab: 'SEL',
            sellerCode: '',
            settleList: [],
            settleCount: { prior: 0, wait: 0, complete: 0 }
        }
    },
    mounted () {
        this.sellerCode = window.sessionStorage.getItem(this.SESSION_SELLER)
        this.retrieveSellerCount()
        // this.retrieveInviterCount()
        this.retrieveSettlePage()
        this.$refs.share.default('seller')
    },
    methods: {
        async retrieveSellerCount () {
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: seller, config: 'PRIOR,WAIT,COMPLETE' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/settle/seller/retrieveStateSettleCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleCount.prior += res.data.prior
                this.settleCount.wait += res.data.wait
                this.settleCount.complete += res.data.complete
            }
        },
        async retrieveInviterCount () {
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: seller }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETInviterCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleCount.prior += res.data.prior
                this.settleCount.wait += res.data.wait
                this.settleCount.complete += res.data.complete
            }
        },
        async retrieveRefereeCount () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { refereeCode: user }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETRefereeCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleCount.prior += res.data.prior
                this.settleCount.wait += res.data.wait
                this.settleCount.complete += res.data.complete
            }
        },
        async retrieveRefereeList () {
            this.loadingShow = true
            this.settleList = []
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { refereeCode: user, current: 1, size: 100 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETRefereeList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleList = res.data
            }
            this.loadingShow = false
        },
        async retrieveSettlePage () {
            this.loadingShow = true
            this.settleList = []
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: seller, current: 1, size: 50 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/settle/seller/retrieveSettlePage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleList = res.data
            }
            this.loadingShow = false
        },
        async retrieveSellerInviterList () {
            this.loadingShow = true
            this.settleList = []
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: seller, current: 1, size: 100 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETInviterList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleList = res.data
            }
            this.loadingShow = false
        },
        tabChange (val) {
            this.activeTab = val
            if (val === 'SEL') {
                this.retrieveSettlePage()
            } else if (val === 'IVT') {
                this.retrieveSellerInviterList()
            }
        }
    }
}
