<template>
  <div class="page">
    <Navbar title="订单结算" type="SMP" />
    <van-row class="settle">
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-m">{{ settleCount.prior }}金豆</div>
        <div class="text">待办理收益</div></van-col
      >
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-s1">{{ settleCount.wait }}金豆</div>
        <div class="text">待结算收益</div></van-col
      >
      <van-col span="8"
        ><div class="amount color-s2">{{ settleCount.complete }}金豆</div>
        <div class="text">已结算收益</div></van-col
      >
    </van-row>
    <van-tabs
      :active="activeTab"
      border="true"
      :color="COLOR"
      @change="tabChange"
    >
      <van-tab title="自推广分成" name="SEL"> </van-tab>
      <van-tab title="子微客分成" name="IVT"> </van-tab>
    </van-tabs>
    <div class="records">
      <van-row
        class="record bdc-b1s-gray"
        v-for="settle in settleList"
        :key="settle.code"
      >
        <van-col span="16" class="content">
          <div class="name text-short">{{ settle.businessName }}</div>
          <div class="time">时间：{{ settle.createTime }}</div>
        </van-col>
        <van-col span="8">
          <div class="amount color-s1">收益:{{ settle.settleAmount }}金豆</div>
          <div class="state">
            <van-tag :color="COLOR_M" plain v-if="settle.state === 'WPC'"
              >待处理</van-tag
            >

            <van-tag :color="COLOR_S1" plain v-if="settle.state === 'WST'"
              >待结算</van-tag
            >
            <van-tag :color="COLOR_S2" plain v-if="settle.state === 'PCS'"
              >结算中</van-tag
            >
            <van-tag :color="COLOR_M" plain v-if="settle.state === 'CPT'"
              >已完成</van-tag
            >
          </div>
        </van-col>
      </van-row>
      <van-row class="no-record" v-if="settleList.length <= 0">
        <van-col span="24">
          <van-image
            width="103"
            height="103"
            :src="require('../../../assets/images/home/no-record.png')"
          ></van-image
        ></van-col>
      </van-row>
    </div>
    <Tabbar active="own" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
